import { signalStore, withComputed, withState } from "@ngrx/signals";
import { withEntities } from "@ngrx/signals/entities";
import { withScriptsApi } from "./scripts-api.feature";
import { ScenarioResponse } from "../../api/models/scenario-response";
import { computed } from "@angular/core";
import { initialScenarioState } from "./script.types";
import { HelperFunctions } from "../../helpers/HelperFunctions";

export const ScriptsStore = signalStore(
    { providedIn: "root", protectedState: false },
    withState(initialScenarioState),
    withEntities<ScenarioResponse>(),
    withScriptsApi(),
    withComputed((store) => ({
        getSelectedScenario: computed(() => {
            return store.entityMap()[store.selected_id()];
        }),

        getFiltredScenarios: computed(() => {
            let eventsFilter: string[] = store.filter();
            if (!eventsFilter.length) {
                return store.entities();
            } else {
                return HelperFunctions.filterArray(store.entities(), 'event_id', eventsFilter);
            }
        })
    })),
)
