import { SearchSelectGroup } from "./SearchSelectGroup.class";
import { HelperFunctions } from "../../../helpers/HelperFunctions";

export class GroupSelectOptionArray extends Array {
    #groups: Record<string, SearchSelectGroup> = {};

    constructor(items: SearchSelectGroup[] = [], test?: any) {
        super();
        if (!items || !Array.isArray(items)) {
            return;
        }
        items.forEach(item => {
            this.push(item);
        });
    }

    getById(id: string = ''): SearchSelectGroup | null {
        id = id.toLowerCase();
        if (!!this.#groups[id]) {
            return this.#groups[id];
        }
        return null;
    }

    removeElById(id: string): void {
        let el: SearchSelectGroup | null = this.getById(id);
        if (!el) {
            return;
        }
        delete this.#groups[id];
        this.splice(this.indexOf(el) , 1);
    }

    override push(item: SearchSelectGroup): number {
        item = this.#updateGroup(item);
        return super.push(item);
    }

    insert(item: SearchSelectGroup): SearchSelectGroup[] {
        item = this.#updateGroup(item);
        return HelperFunctions.insertItemInSortedArray(this, item, 'sort');
    }

    #updateGroup(item: SearchSelectGroup): SearchSelectGroup {
        if (!(item instanceof SearchSelectGroup)) {
            item = new SearchSelectGroup(item);
        }

        if (!this.#groups[item.getId().toLowerCase()]) {
            this.#groups[item.getId().toLowerCase()] = item;
        }

        return item;
    }
}
